import { Accessor, onCleanup } from "solid-js";

export function clickOutside(el: HTMLElement, accessor: Accessor<Function>) {
  const onClick = (e: MouseEvent) => {
    return !el.contains(e.target as HTMLElement) && accessor()?.();
  };

  document.body.addEventListener("click", onClick);

  onCleanup(() => document.body.removeEventListener("click", onClick));
}
export type ClickOutside = typeof clickOutside;

export function clickOnSameElement(
  el: HTMLElement,
  accessor: Accessor<Function>,
) {
  const onClick = (e: MouseEvent) => {
    if (e.target === el) {
      e.stopImmediatePropagation();
      accessor()?.(e);
    }
  };

  el.addEventListener("click", onClick, { capture: true });
  onCleanup(() => el.removeEventListener("click", onClick));
}
export type ClickOnSameElement = typeof clickOnSameElement;
