import { JSX, ParentProps, Show } from "solid-js";
import { Portal } from "solid-js/web";

import { css } from "#style/css";
import { styled, VStack, Box } from "#style/jsx";

import { clickOutside } from "#root/module/html";

import { Typography } from "./Typography";

// Workaround to keep the import of the directive
false && clickOutside;

type RootProps = ParentProps<{
  ref?: HTMLDialogElement | ((el: HTMLDialogElement) => void);
  header?: JSX.Element;
  footer?: JSX.Element;
  onBackdropClicked?: () => void;
  onClose?: () => void;
  open: boolean;
}>;
function Root(props: RootProps) {
  return (
    <Portal>
      <styled.dialog
        open={props.open}
        ref={props.ref}
        width="full"
        height="full"
        position="fixed"
        zIndex="1000"
        top="0"
        left="0"
        backgroundColor="rgba(255,255,255, 0.2)"
        _backdrop={{
          backgroundColor: "transparent",
        }}
        onClose={props.onClose}
      >
        <VStack
          width="full"
          height="full"
          justifyContent="flex-start"
          alignItems="center"
        >
          <div
            use:clickOutside={props.onBackdropClicked}
            class={css({
              backgroundColor: "linen",
              width: "full",
              lgDown: {
                h: "full",
              },
              lg: {
                mt: "10%",
                borderRadius: "md",
                maxW: "500px",
                boxShadow: "inset 0px 0px 1px 0px #000000",
              },
            })}
          >
            <Show when={props.header}>
              {(headerComponent) => headerComponent()}
            </Show>
            {props.children}
            <Show when={props.footer}>
              {(footerComponent) => footerComponent()}
            </Show>
          </div>
        </VStack>
      </styled.dialog>
    </Portal>
  );
}

type HeaderProps = ParentProps<{
  title: string;
}>;
function Header(props: HeaderProps) {
  return (
    <styled.header py="2" px="4">
      <Typography tag="h3" textStyle="smallImportant">
        {props.title}
      </Typography>
    </styled.header>
  );
}

type ContentProps = ParentProps<{}>;
function Content(props: ContentProps) {
  return (
    <Box px="6" py="4" w="full">
      {props.children}
    </Box>
  );
}

type FooterProps = ParentProps<{
  class?: string;
}>;
function Footer(props: FooterProps) {
  return (
    <styled.footer
      class={props.class}
      w="full"
      py="4"
      px="4"
      borderBottomRadius="sm"
    >
      {props.children}
    </styled.footer>
  );
}

export const Modal = {
  Root,
  Header,
  Content,
  Footer,
};
