import { Component, Show } from "solid-js";

import { styled } from "#style/jsx";
import { css } from "#style/css";
import { useTranslation } from "#root/domain/i18n";

import { Field } from "./Field";
import { Typography } from "./Typography";
import { Dynamic } from "solid-js/web";

type InputFieldProps = {
  label?: string;
  name?: string;
  optional?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  type: "text" | "email" | "password";
  autocomplete?: boolean;
  multiline?: boolean;
};

export const InputField: Component<InputFieldProps> = (
  props: InputFieldProps,
) => {
  const { t } = useTranslation();

  const inputId = () => props.name + "_id_input";
  const tagName = () => (props.multiline ? "textarea" : "input");

  return (
    <Field.Container>
      <Show when={props.label}>
        <Field.Label for={inputId()}>
          {props.label}
          <Show when={props.optional}>
            <Typography
              tag="span"
              textStyle="xxsmallImportant"
              class={css({ color: "whiteSmoke.500" })}
            >
              {t("common.form.optional")}
            </Typography>
          </Show>
        </Field.Label>
      </Show>
      <Dynamic<"input" | "textarea">
        component={tagName()}
        id={inputId()}
        aria-disabled={props.disabled}
        name={props.name}
        value={props.value}
        type={props.type}
        autocomplete={props.autocomplete ? undefined : "off"}
        onChange={(event: Event) =>
          props.onChange?.(
            (event.target as HTMLInputElement | HTMLTextAreaElement).value,
          )
        }
        disabled={props.disabled}
        class={css({
          textStyle: "xsmall",
          w: "full",
          px: "4",
          py: "2",
          borderRadius: "sm",
          border: "thin solid",
          backgroundColor: "white",
          _focus: {
            outlineColor: "yellow",
          },
          _hover: {
            borderColor: "yellow",
            _focus: {
              outlineColor: "yellow",
            },
          },
          _disabled: {
            backgroundColor: "lightGray",
            color: "mediumGray",
          },
        })}
      />
    </Field.Container>
  );
};
